import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IBookingActionNames, IBusinessType, ICancelReasons, ICategory, ICountry, IFloors, ISponsorTypes, IState } from '../master-data/master-data';
import { MasterDataDisplayColumns } from '../../admin/admin';

@Injectable({ providedIn: 'root' })
export class MasterDataService {

  private bedTypeSource = new BehaviorSubject<any>({});
  bedType$ = this.bedTypeSource.asObservable();


  private propertyTypeSource = new BehaviorSubject<any>({});
  propertyType$ = this.propertyTypeSource.asObservable();

  private businessTypeSource = new BehaviorSubject<IBusinessType[]>([]);
  businessType$ = this.businessTypeSource.asObservable();

  private washroomTypeSource = new BehaviorSubject<any>({});
  washroomType$ = this.washroomTypeSource.asObservable();

  private stateSource = new BehaviorSubject<IState[]>([]);
  state$ = this.stateSource.asObservable();

  private countrySource = new BehaviorSubject<ICountry[]>([]);
  country$ = this.countrySource.asObservable();

  private floorNamesSource = new BehaviorSubject<IFloors[]>([]);
  floorNames$ = this.floorNamesSource.asObservable();

  private cancelReasonsSource = new BehaviorSubject<ICancelReasons[]>([]);
  cancelReasons$ = this.cancelReasonsSource.asObservable();

  private bookingActionNamesSource = new BehaviorSubject<IBookingActionNames[]>([]);
  bookingActionNames$ = this.bookingActionNamesSource.asObservable();

  private sponsorTypesSource = new BehaviorSubject<ISponsorTypes[]>([]);
  sponsorTypes$ = this.sponsorTypesSource.asObservable();

  private categoriesSource = new BehaviorSubject<ICategory[]>([]);
  category$ = this.categoriesSource.asObservable();



  private bedTypeJsonPath = '../../../assets/master-data/bed-types.json';
  private washroomTypesJsonPath = '../../../assets/master-data/washroom-types.json';
  private propertyTypesJsonPath = '../../../assets/master-data/property-types.json';
  private businessTypesJsonPath = '../../../assets/master-data/business-types.json';
  private statesJsonPath = '../../../assets/master-data/states.json';
  private countriesJsonPath = '../../../assets/master-data/countries.json';
  private floorNamesJsonPath = '../../../assets/master-data/floor-names.json';
  private cancelReasonsJsonPath = '../../../assets/master-data/cancel-reasons.json';
  private bookingActionNamesJsonPath = '../../../assets/master-data/booking-action-names.json';
  private sponsorTypesJsonPath = '../../../assets/master-data/sponsor-types.json';
  private categoriesJsonPath = '../../../assets/master-data/categories.json';

  //Master Data Types
  public masterDataDisplayColumns: MasterDataDisplayColumns = {
    bedTypes: ['bed_type_id', 'bed_type', 'is_active', 'actions'],
    propertyTypes: ['property_type_id', 'property_type', 'is_active', 'actions'],
    businessTypes: ['business_type_id', 'business_type', 'is_active', 'actions'],
    washRoomTypes: ['washroom_type_id', 'washroom_type', 'is_active', 'actions'],
    countries: ['country_id', 'country_name', 'country_code_alpha_2', 'country_code_alpha_3', 'country_isd_code', 'currency_name', 'currency_code', 'is_active', 'actions'],
    states: ['state_id', 'country_id', 'state_code', 'state_name', 'is_union_territory', 'is_active', 'actions'],
    floorNames: ['floor_name_id', 'floor_name', 'is_active', 'actions'],
    cancelReasons: ['cancel_reason_id', 'cancel_reason', 'is_active', 'actions'],
    bookingActionNames: ['booking_action_name_id', 'action_name', 'is_active', 'actions'],
    sponsorTypes: ['sponsor_type_id', 'sponsor_type', 'is_active', 'actions'],
    categories: ['category_id', 'category', 'is_active', 'actions'],

  }






  constructor(private readonly http: HttpClient) {
    console.log('master data service');
  }


  loadBedTypes() {
    this.http.get<any>(this.bedTypeJsonPath).subscribe(data => {
      this.bedTypeSource.next(data.bedTypes);
    })
  }

  loadWashroomTypes() {
    this.http.get<any>(this.washroomTypesJsonPath, { responseType: 'json' }).subscribe(data => {
      this.washroomTypeSource.next(data.washroomTypes);
    })
  }

  loadPropertyTypes() {
    //console.log('load prop');
    this.http.get<any>(this.propertyTypesJsonPath, { responseType: 'json' }).subscribe(data => {
      this.propertyTypeSource.next(data.propertyTypes);
    })
  }

  loadBusinessTypes() {
    this.http.get<any>(this.businessTypesJsonPath, { responseType: 'json' }).subscribe(data => {
      this.businessTypeSource.next(data.businessTypes);
    })
  }

  loadStates() {
    this.http.get<any>(this.statesJsonPath).subscribe(data => {
      this.stateSource.next(data.states);
    })
  }


  loadCountries() {
    this.http.get<any>(this.countriesJsonPath).subscribe(data => {
      this.countrySource.next(data.countries);
    })
  }

  loadFloors() {
    this.http.get<any>(this.floorNamesJsonPath).subscribe(data => {
      this.floorNamesSource.next(data.floorNames);
    })
  }

  loadCancelReasons() {
    this.http.get<any>(this.cancelReasonsJsonPath).subscribe(data => {
      this.cancelReasonsSource.next(data.cancelReasons);
    })
  }

  loadbookingActionNames() {
    this.http.get<any>(this.bookingActionNamesJsonPath).subscribe(data => {
      this.bookingActionNamesSource.next(data.bookingActionNames);
    })
  }

  loadsponsorTypes() {
    this.http.get<any>(this.sponsorTypesJsonPath).subscribe(data => {
      this.sponsorTypesSource.next(data.sponsorTypes);
    })
  }
  loadCategories() {
    this.http.get<any>(this.categoriesJsonPath).subscribe(data => {
      this.categoriesSource.next(data.categories);
    })
  }



  loadMasterData() {

    this.loadBedTypes();
    this.loadWashroomTypes();
    this.loadPropertyTypes();
    this.loadBusinessTypes();
    this.loadStates();
    this.loadCountries();
    this.loadFloors();
    this.loadCancelReasons();
    this.loadbookingActionNames();
    this.loadsponsorTypes();
    this.loadCategories();
  }


}
